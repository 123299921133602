@import '../../../../scss/theme-bootstrap';

.signin-widget__formatter {
  @include breakpoint($medium-up) {
    display: none;
  }
  &-content {
    overflow: hidden;
    padding: 0 20px;
    text-align: center;
  }
  &-message {
    text-transform: capitalize;
  }
  &-message,
  &-offers-link {
    color: inherit;
    display: inline-block;
    line-height: 2.7;
  }
  &-offers-link {
    margin-#{$ldirection}: 5px;
    text-decoration: underline;
  }
  &-toggle {
    background: none;
    border: 0;
    color: inherit;
    float: $rdirection;
    font-size: 20px;
    min-width: auto;
    padding: 9px 0;
    transition: 0.1s linear;
    &:active,
    &:hover {
      background: none;
      color: inherit;
    }
    .icon--caret--down2 {
      width: 20px;
      height: 20px;
    }
  }
  &-toggle-expanded {
    transform: rotate(-180deg);
  }
  .signin-widget {
    border-top: solid 1px $color-belgian-linen;
    clear: both;
  }
}
